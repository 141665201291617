import { Form, Input, InputNumber, Modal, Space } from 'antd';
import React from 'react';
import styles from './index.less';
import type { MainItem } from './types';

interface AddAndEditModalProps {
  title: string;
  onCancel: () => void;
  callback: (params: MainItem) => Promise<unknown>;
  initialValues?: MainItem;
}

export function AddAndEditModal({
  onCancel,
  callback,
  initialValues,
  title,
}: AddAndEditModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: MainItem; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title={title}
      visible
      width={480}
    >
      <div className={styles.addAndEditModalContainer}>
        <Form
          form={form}
          initialValues={initialValues}
          layout="horizontal"
          onFinish={handleFinish}
        >
          <Form.Item
            label="设备名称"
            name="deviceName"
            rules={[
              {
                required: true,
                message: '请输入设备名称',
              },
            ]}
          >
            <Input
              allowClear
              autoFocus
              maxLength={50}
              placeholder="请输入设备名称"
            />
          </Form.Item>
          <Form.Item
            label="厂商"
            name="manufacturer"
            rules={[
              {
                required: true,
                message: '请输入厂商',
              },
            ]}
          >
            <Input
              allowClear
              maxLength={50}
              placeholder="请输入厂商"
            />
          </Form.Item>
          <Space>

            <Form.Item
              label="设备成本"
              name="cost"
              rules={[
                {
                  required: true,
                  message: '请输入设备成本',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最小为1',
                },
              ]}
            >
              <InputNumber
                max={9999999}
                min={0}
                placeholder="请输入设备成本"
                style={{ width: 150 }}
              />
            </Form.Item>
            <div style={{ marginTop: -20 }}>
              元
            </div>
          </Space>
          <Space>
            <Form.Item
              label="占地面积"
              name="areaCovered"
              rules={[
                {
                  required: true,
                  message: '请输入占地面积',
                },
                {
                  type: 'number',
                  min: 1,
                  message: '最小为1',
                },
              ]}
            >
              <InputNumber
                max={999999}
                min={0}
                placeholder="请输入占地面积"
                style={{ width: 150 }}
              />
            </Form.Item>
            <div style={{ marginTop: -20 }}>
              平方
            </div>
          </Space>
        </Form>
      </div>
    </Modal>
  );
}
