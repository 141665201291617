import { message, Modal } from 'antd';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import type { NormalProgramme, MainSubStructureModel } from 'egenie-utils';
import { action } from 'mobx';
import React from 'react';
import { AddAndEditModal } from './addAndEditModal';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';
import type { MainItem } from './types';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;
    this.mainSubStructureModel.onQuery();
  }

  public mainSubStructureModel: MainSubStructureModel;

  public normalProgramme: NormalProgramme;

  @action public handleAddAndEditDevice = async(item?: MainItem) => {
    const isEdit = Boolean(item?.wmsDeviceConfigId);
    if (isEdit && item?.enable) {
      const isContinue = await new Promise((resolve) => {
        Modal.confirm({
          title: '设备已应用,确定编辑设备吗?',
          content: '编辑后会影响已经应用的方案',
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        });
      });

      if (!isContinue) {
        return;
      }
    }

    renderModal(
      <AddAndEditModal
        callback={((data) => {
          return request({
            url: '/api/cloud/wms/rest/cloud/report/device/addOrEditDevice',
            method: 'post',
            data: {
              deviceId: item?.wmsDeviceConfigId,
              ...data,
            },
          })
            .then(() => {
              this.mainSubStructureModel.gridModel.resetAll();
              this.mainSubStructureModel.gridModel.onQuery();
              message.success('操作成功');
              destroyAllModal();
            });
        })}
        initialValues={item}
        onCancel={destroyAllModal}
        title={isEdit ? '编辑设备' : '新建设备'}
      />
    );
  };

  @action public handleDeleteDevice = (item: MainItem) => {
    Modal.confirm({
      content: '确定删除吗?',
      onOk: () => request({ url: `/api/cloud/wms/rest/cloud/report/device/deleteDevice/${item.wmsDeviceConfigId}` })
        .then(() => {
          this.mainSubStructureModel.gridModel.resetAll();
          this.mainSubStructureModel.gridModel.onRefresh();
          message.success('删除成功');
        }),
    });
  };
}

