import { Badge, Button, Space } from 'antd';
import type { PaginationData } from 'egenie-common';
import { request } from 'egenie-common';
import { MainSubStructureModel } from 'egenie-utils';
import React from 'react';
import type { Store } from './store';
import type { MainItem } from './types';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    // pageId: '60099',
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          frozen: true,
          width: 120,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <Space size="small">
                <Button
                  onClick={() => context.handleAddAndEditDevice(row)}
                  size="small"
                  type="link"
                >
                  编辑
                </Button>
                {
                  row.enable ? null : (
                    <Button
                      onClick={() => context.handleDeleteDevice(row)}
                      size="small"
                      type="link"
                    >
                      删除
                    </Button>
                  )
                }
              </Space>
            );
          },
        },
        {
          key: 'deviceName',
          name: '设备名称',
        },
        {
          key: 'manufacturer',
          name: '厂商',
        },
        {
          key: 'cost',
          name: '设备成本',
          width: 100,
        },
        {
          key: 'areaCovered',
          name: '占地面积(平方)',
          width: 100,
        },
        {
          key: 'enable',
          name: '设备应用状态',
          width: 100,
          formatter: ({ row }: { row: MainItem; }) => (
            <Badge
              status={row.enable ? 'success' : 'default'}
              text={row.enable ? '已应用' : '未应用'}
            />
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsDeviceConfigId',
      sortByLocal: false,
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
      showEmpty: true,
      pageSize: 50,
    },
    buttons: [
      {
        text: '新增设备',
        handleClick: () => context.handleAddAndEditDevice(),
      },
    ],
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/cloud/report/device/getDeviceList',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });
}
