import { NormalProgramme } from 'egenie-utils';
import type { Store } from './store';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'deviceName',
        label: '设备名称',
      },
      {
        type: 'select',
        field: 'enable',
        label: '应用状态',
        data: [
          {
            value: '1',
            label: '已应用',
          },
          {
            value: '0',
            label: '未应用',
          },
        ],
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
